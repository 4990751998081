import {colors, gridConfig} from "./config";
import {fabric} from "fabric";

export let isTextEvent = false;

export const canvas = new fabric.Canvas("c", {
    selection: false,
    backgroundColor: colors.canvasBg
});

export const grid = gridConfig.steps;

export const lineControlDefaults = {
    bl: false,
    br: false,
    tl: false,
    tr: false,
    ml: false,
    mr: false,
    // ha mégis kell a forgatás akkor ezt true-ra kell tenni
    mtr: false
};

export const objectDefaults = {
    stroke: colors.shapeStroke,
    strokeWidth: 1,
    selectable: true,
    padding: 5
};

export const topRuler = new fabric.Canvas('top-ruler');
export const leftRuler = new fabric.Canvas('left-ruler');

export const objPropsByType = [
    {type: 'rect', keys: ['width', 'scaleX'], label: 'szélesség'},
    {type: 'rect', keys: ['height', 'scaleY'], label: 'magasság'},
    {type: 'line', keys: ['height', 'scaleY'], label: 'hosszúság'}
];