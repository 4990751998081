import {canvas, objPropsByType} from "./shared";
import {fabric} from "fabric";

let activeObjDimensions = [];

function isDimensionsNeeded(activeObject) {
    if (activeObject.length > 1 || activeObject.length === 0) {
        return false;
    }

    if (activeObject.length === 1) {
        let type = activeObject[0].get('type');
        if (type !== 'rect' && type !== 'line') {
            return false;
        }
    }
    return true;
}

export function getObjectDimensions() {
    let activeObject = canvas.getActiveObjects();
    if (!isDimensionsNeeded(activeObject)) {
        activeObjDimensions = [];
        hideSizes();
    } else {
        activeObject = activeObject[0];
        // milyen típusú az objektum
        let type = activeObject.get('type');

        // melyik propertyket kell lekérdezni
        let propertiesToGet = objPropsByType.filter((el) => el.type === type);
        if (propertiesToGet) {
            // objektum méret adatai
            activeObjDimensions = propertiesToGet.map(function (el) {
                return {
                    'type': el.type,
                    'label': el.label,
                    'keys': el.keys,
                    // ha scale-elve lett, akkor a méret = az eredeti objektum * scale értéke
                    'value': (activeObject.get(el.keys[0]) * activeObject.get(el.keys[1])).toFixed(2)
                }
            });
        }
        showSizes();
    }

    // add to the DOM
    let cont = document.getElementById('dimensions-form');
    cont.textContent = '';
    activeObjDimensions.forEach(function (el, idx) {
        let col = document.createElement('div');
        if (idx === 0) {
            col.classList.add('me-1');
        } else {
            col.classList.add('ms-1');
        }
        col.classList.add('col');

        let input = document.createElement('input');
        input.id = el.keys[0];
        input.classList.add('form-control');
        input.classList.add('form-control-sm');
        input.setAttribute('type', 'number');
        input.setAttribute('step', '0.01');
        input.setAttribute('min', '1');
        input.setAttribute('name', el.keys.join('-'));
        input.value = el.value;

        col.appendChild(input);

        let small = document.createElement('small');
        small.classList.add('form-text');
        small.classList.add('text-muted');
        small.textContent = el.label;

        col.appendChild(small);

        cont.appendChild(col);
    });
}

export function addDimension() {
    let object = canvas.getActiveObjects();
    let location;
    let center;

    if (object.length === 1) {
        object = object[0];
        if (object.get('type') === 'rect') {
            location = object.getBoundingRect(true);
            activeObjDimensions.forEach(function (el) {
                let top;
                let left;
                let angle;

                if (el.keys.indexOf('width') > -1) {
                    top = location.top - 10;
                    left = location.left + (el.value / 2) - 10;
                    angle = 0;
                } else {
                    top = location.top + (el.value / 2) + 10;
                    left = location.left - 10;
                    angle = -90;
                }

                let text = new fabric.IText(`${el.value}`, {
                    fontFamily: 'arial',
                    fill: '#0069d9',
                    left: left,
                    top: top,
                    angle: angle,
                    fontSize: 10
                });
                canvas.add(text);
            });
        }

        if (object.get('type') === 'line') {
            location = object.getBoundingRect(true);
            center = object.getCenterPoint();

            let left;
            let top;

            activeObjDimensions.forEach(function (el) {
                let objectAngle = object.get('angle');
                let angle;

                // vizszintes vonal
                if (objectAngle === 90) {
                    left = location.left + el.value / 2 - 10;
                    top = location.top - 10;
                    angle = 0;
                }
                // függőleges vonal
                if (objectAngle === 0) {
                    left = location.left - 10;
                    top = location.top + (el.value / 2) + 10;
                    angle = -90;
                }

                if (objectAngle === 45) {
                    left = center.x;
                    top = center.y;
                    angle = -45;
                }

                if (objectAngle === 135) {
                    left = center.x;
                    top = center.y;
                    angle = 45;
                }

                let text = new fabric.IText(`${el.value}`, {
                    fontFamily: 'arial',
                    fill: '#0069d9',
                    left: left,
                    top: top,
                    angle: angle,
                    fontSize: 10
                });
                canvas.add(text);
            });
        }
    }
}

export function resizeClickHandler() {
    let elements = document.getElementById("dimensions-form").elements;
    let obj = {};
    for (let i = 0; i < elements.length; i++) {
        let item = elements.item(i);
        let value = parseFloat(item.value);
        if (value) {
            if (value === 0) {
                value = 1;
            }

            if (value < 0) {
                value *= -1;
            }
            obj[item.name] = value;
        }
    }

    resizeObject(obj);
}

export function resizeObject(sizes) {
    let scaleSizes = [];

    for (const prop in sizes) {
        scaleSizes.push({keys: prop.split('-'), value: sizes[prop]})
    }

    let activeObj = canvas.getActiveObjects();
    if (activeObj.length === 1) {
        activeObj = activeObj[0];
        const scale = activeObj.getObjectScaling();
        scaleSizes.forEach(function (el) {
            activeObj.set(el.keys[0], el.value / scale[el.keys[1]])
        });
    }
    canvas.requestRenderAll();

    getObjectDimensions();
}

function hideSizes() {
    document.getElementById('size-section').classList.add('d-none');
}

function showSizes() {
    document.getElementById('size-section').classList.remove('d-none');
}