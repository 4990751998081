const colors = {
    shapeStroke: '#000',
    shapeFill: '#fff',
    canvasBg: '#fff',
    canvasStrokeDark: '#e6e6e6',
    canvasStrokeLight: '#f5f5f5'
};

const gridConfig = {
    steps: 5
};

const canvasConfig = {
    size: 600
};

export const movingSteps = 1;

export {colors, gridConfig, canvasConfig};