import {canvas, objectDefaults, lineControlDefaults} from "./shared";
import {colors} from "./config";
import {objSnapToGrid} from "./utils";
import {isTextEvent} from "./shared";

/**
 * Drawing tools
 */
export function drawRect(x, y) {
    let rect;
    canvas.isDrawingMode = false;
    rect = new fabric.Rect({
        ...objectDefaults,
        left: x,
        top: y,
        width: 50,
        height: 50,
        fill: colors.shapeFill,
        originX: "left",
        originY: "top",
        noScaleCache: false,
        strokeUniform: true
    }).setControlsVisibility({'mtr': false})
    canvas.add(rect);

    objSnapToGrid(rect);
}

export function drawLineY(x, y) {
    canvas.isDrawingMode = false;
    let line = new fabric.Line([250, 125, 250, 175], {
        ...objectDefaults,
        top: y,
        left: x
    }).setControlsVisibility(lineControlDefaults);
    canvas.add(line);

    objSnapToGrid(line);
}

export function drawLineX(x, y) {
    canvas.isDrawingMode = false;
    let line = new fabric.Line([250, 125, 250, 175], {
        ...objectDefaults,
        top: y,
        left: x,
        angle: 90
    }).setControlsVisibility(lineControlDefaults);
    canvas.add(line);

    objSnapToGrid(line);
}

export function drawLineAngle1(x, y) {
    canvas.isDrawingMode = false;
    let line = new fabric.Line([250, 125, 250, 175], {
        ...objectDefaults,
        top: y,
        left: x,
        angle: 135
    }).setControlsVisibility(lineControlDefaults);
    canvas.add(line);

    objSnapToGrid(line);
}

export function drawLineAngle2(x, y) {
    canvas.isDrawingMode = false;
    let line = new fabric.Line([250, 125, 250, 175], {
        ...objectDefaults,
        top: y,
        left: x,
        angle: 45
    }).setControlsVisibility(lineControlDefaults);
    canvas.add(line);

    objSnapToGrid(line);
}

export function freehandTool() {
    canvas.isDrawingMode = true;
}

export function textTool(x, y) {
    canvas.isDrawingMode = false;

    let text = new fabric.IText('gépelje be a szöveget', {
        fontFamily: 'arial',
        left: x,
        top: y,
        fontSize: 14
    });

    canvas.add(text);

    text.on('selection:changed', function () {
        isTextEvent = true;
    });

    text.on('editing:exited', function () {
        isTextEvent = false;
    })
}

export function moveTool(opt) {
    let evt = opt.e;
    canvas.isDragging = true;
    canvas.selection = false;
    canvas.lastPosX = evt.clientX;
    canvas.lastPosY = evt.clientY;
}