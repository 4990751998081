import {canvas} from "./shared";
import {colors} from "./config";
import {createGrid, createRulerNumbers, drawRulers} from "./utils";
import {clearHistory} from "./history";

export function clearCanvas() {
    if (confirm("Biztos hogy törölni szeretné a vásznon lévő összes elemet?")) {
        // reset zoom
        canvas.setZoom(1);
        canvas.clear();
        canvas.setBackgroundColor(colors.canvasBg);
        createGrid();
        drawRulers();
        createRulerNumbers();
        clearHistory();
    }
}