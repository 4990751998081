import {grid, canvas, topRuler, leftRuler} from './shared';
import {canvasConfig, gridConfig, colors} from "./config";
import {fabric} from "fabric";

export function objSnapToGrid(obj) {
    let target = obj,
        w = obj.width * obj.scaleX,
        h = obj.height * obj.scaleY,
        snap = { // Closest snapping points
            top: Math.round(target.top / grid) * grid,
            left: Math.round(target.left / grid) * grid,
            bottom: Math.round((target.top + h) / grid) * grid,
            right: Math.round((target.left + w) / grid) * grid
        };

    obj.left = snap.left;
    obj.top = snap.top;
}

export function enableBtnById(id) {
    let btn = document.getElementById(id);
    if (btn) {
        btn.removeAttribute('disabled');
    }
}

// create grid
export function createGrid() {
    for (let i = 0; i < canvasConfig.size / grid; i++) {
        let stroke = colors.canvasStrokeLight;
        if (i % (gridConfig.steps * 2) !== 0 && i % gridConfig.steps !== 0) {
            canvas.add(
                new fabric.Line([i * grid, 0, i * grid, canvasConfig.size], {
                    selectable: false,
                    hasControls: false,
                    hoverCursor: "default",
                    stroke: stroke
                })
            );
            canvas.add(
                new fabric.Line([0, i * grid, canvasConfig.size, i * grid], {
                    selectable: false,
                    hasControls: false,
                    hoverCursor: "default",
                    stroke: stroke
                })
            );
        }
    }

    for (let k = 0; k < canvasConfig.size / grid; k++) {
        let stroke = colors.canvasStrokeLight;
        if (k % gridConfig.steps === 0) {
            canvas.add(
                new fabric.Line([k * grid, 0, k * grid, canvasConfig.size], {
                    selectable: false,
                    hasControls: false,
                    hoverCursor: "default",
                    stroke: stroke
                })
            );
            canvas.add(
                new fabric.Line([0, k * grid, canvasConfig.size, k * grid], {
                    selectable: false,
                    hasControls: false,
                    hoverCursor: "default",
                    stroke: stroke
                })
            );
        }
    }

    for (let j = 0; j < canvasConfig.size / grid; j++) {
        let stroke = colors.canvasStrokeDark;
        if (j % (gridConfig.steps * 2) === 0) {
            canvas.add(
                new fabric.Line([j * grid, 0, j * grid, canvasConfig.size], {
                    selectable: false,
                    hasControls: false,
                    hoverCursor: "default",
                    stroke: stroke
                })
            );
            canvas.add(
                new fabric.Line([0, j * grid, canvasConfig.size, j * grid], {
                    selectable: false,
                    hasControls: false,
                    hoverCursor: "default",
                    stroke: stroke
                })
            );
        }
    }
}

export function drawRulers() {
    topRuler.clear();
    leftRuler.clear();

    let zoomLevel = canvas.getZoom();

    let j = 0;
    for (let i = 0; i < 600; i += (10 * zoomLevel)) {
        let stroke, top;

        j++;

        if ((j - 1) % 5 === 0) {
            stroke = colors.canvasStrokeLight;
            top = 0;
        } else {
            stroke = colors.canvasStrokeDark;
            top = 10;
        }

        let topLine = new fabric.Line([i, 25, i, top], {
            selectable: false,
            hasControls: false,
            hoverCursor: "default",
            stroke: stroke
        });
        topRuler.add(topLine);

        let leftLine = new fabric.Line([25, i, top, i], {
            selectable: false,
            hasControls: false,
            hoverCursor: "default",
            stroke: stroke
        });
        leftRuler.add(leftLine);
    }

    // Numbers
    for (let i = 0; i < 600; i += (50 * zoomLevel)) {
        let text = new fabric.Text((Math.round(i / zoomLevel)).toString(), {
            selectable: false,
            hasControls: false,
            hoverCursor: "default",
            left: i + 3,
            top: 0,
            fontSize: 10
        });
        topRuler.add(text);

        let text2 = new fabric.Text((Math.round(i / zoomLevel)).toString(), {
            selectable: false,
            hasControls: false,
            hoverCursor: "default",
            left: 0,
            top: i + 3,
            fontSize: 10
        });

        leftRuler.add(text2);
    }
}

export function createRulerNumbers() {
    let zoomLevel = canvas.getZoom();

    for (let i = 0; i < 600; i += (50 * zoomLevel)) {
        let horizontalText = new fabric.Text((Math.round(i / zoomLevel)).toString(), {
            selectable: false,
            hasControls: false,
            hoverCursor: "default",
            left: i + 3,
            top: 0,
            fill: '#0069d9',
            fontFamily: 'Arial',
            fontWeight: '600',
            fontSize: zoomLevel * 8,
            id: 'yNumbers'
        });

        canvas.add(horizontalText);

        if (i !== 0) {
            let verticalText = new fabric.Text((Math.round(i / zoomLevel)).toString(), {
                selectable: false,
                hasControls: false,
                hoverCursor: "default",
                left: 0,
                top: i + 3,
                fill: '#0069d9',
                fontFamily: 'Arial',
                fontWeight: '600',
                fontSize: zoomLevel * 8,
                id: 'xNumbers'
            });

            canvas.add(verticalText);
        }
    }
}

export function disableBtnById(id) {
    let btn = document.getElementById(id);
    if (btn) {
        btn.setAttribute('disabled', 'true');
    }
}