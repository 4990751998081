import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';

// Create an instance of Notyf
const notyf = new Notyf({
    position: {
        x: 'left',
        y: 'top',
    }
});

export function showSuccess(msg) {
    notyf.success(msg);
}

export function showError(msg) {
    notyf.error(msg);
}

