import {canvas} from "./shared";
import {showError, showSuccess} from "./notification";

export function loadFromLocalStorage() {
    if (localStorage.getItem("myCanvas")) {
        try {
            if (confirm('Szeretné visszatölteni az előző munkamenetben rajzolt elemeket?')) {
                let canvasData = JSON.parse(localStorage.getItem("myCanvas"));
                canvas.loadFromJSON(canvasData, canvas.renderAll.bind(canvas));
                canvas.renderAll();
                showSuccess('Sikeres munkamenet visszatöltés')
            }
        } catch (e) {
            console.error(e);
            showError('Sikertelen munkamenet visszatöltés')
        }
    }
}

export function saveToStorage() {
    try {
        canvas.setZoom(1);
        let jsonData = JSON.stringify(
            canvas.toJSON(["centeredRotation", '_controlsVisibility', 'strokeUniform', 'padding', 'noScaleCache', 'selectable', 'hoverCursor', 'stroke'])
        );
        localStorage.setItem("myCanvas", jsonData);
        showSuccess('Sikeres munkamenet mentés');
    } catch (e) {
        console.error(e);
        showError('Sikertelen munkamenet mentés');
    }
}

export function clearStorage() {
    if (confirm("Biztos hogy törölni szeretné a böngészőbe mentett munkamenetet?")) {
        try {
            window.localStorage.removeItem('myCanvas');
            showSuccess('Sikeres munkamenet törlés');
        } catch (e) {
            console.error(e);
            showError('Sikertelen munkamenet törlés');
        }
    }
}