import {canvas} from "./shared";
import {showError, showSuccess} from "./notification";
import config from '../config.json';
import axios from "axios";
import isValid from './validation';
import {sendModal} from "./index";

let validFileExtensions = ['json'];

function downloadElementAndClick(data, fileExtension) {
    let el = document.createElement('a');
    el.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
    el.setAttribute('download', 'document-' + new Date().toISOString().substring(0, 10) + '-' + new Date().getTime() + fileExtension);
    el.style.display = 'none';
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
}

export function svgExport() {
   let svgData = getSvg();
    downloadElementAndClick(svgData, '.svg');
}

export function getSvg() {
    canvas.setZoom(1);
    return canvas.toSVG();
}

export function getJson() {
    canvas.setZoom(1);
    return JSON.stringify(
        canvas.toJSON(["centeredRotation", '_controlsVisibility', 'strokeUniform', 'padding', 'noScaleCache', 'selectable', 'hoverCursor', 'stroke'])
    );
}

export function showSendModal()
{
    sendModal.show();
}

export function sendAsEmail(e) {
    e.preventDefault();
    const sendModalSubmitButton = document.getElementById('sendModalSubmitButton');
    sendModalSubmitButton.disabled = true;

    grecaptcha.ready(function() {
        grecaptcha.execute('6LdSk3kaAAAAADQnoyeZGPwWuID_McfGBCFZvtBQ', {action: 'submit'}).then(function(token) {

            const emailAddress = document.getElementById('email').value;
            const message = document.getElementById('message').value;

            if (isValid({type: 'email', data: emailAddress})) {
                const formData = new FormData();

                formData.append("svgData", getSvg());
                formData.append("jsonData", getJson());
                formData.append("emailAddress", emailAddress);
                formData.append("message", message);
                formData.append("token", token.toString());

                axios.post(config.emailSendUrl, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                }).then(() => {
                    showSuccess('Sikeres email küldés');
                    sendModalSubmitButton.disabled = false;
                    sendModal.hide();
                }).catch((err) => {
                    console.error('Error while sending email', err);
                    sendModalSubmitButton.disabled = false;
                    showError('Hiba törént az email küldés során!')
                });
            } else {
                sendModalSubmitButton.disabled = false;
                showError('A megadott email cím nem helyes!')
            }

        });
    });

    // let emailAddress = prompt('Kérem adja meg az e-mail címét:');
    // const emailAddress = document.getElementById("email");
    // const message = document.getElementById("message");




}

export function jsonExport() {
    let jsonData = getJson();
    downloadElementAndClick(jsonData, '.json');
}

function isExtensionValid(file) {
    let nameParts = file.name.split('.');
    if (nameParts.length > 1) {
        let ext = nameParts[nameParts.length - 1];
        return validFileExtensions.indexOf(ext) > -1;
    }
    return false;
}

function handleFileInputChange(e) {
    let file = e.target.files[0];
    if(!file || !isExtensionValid(file)) return;
    let reader = new FileReader();

    reader.addEventListener('load', function(f){
        let data = f.target.result;
        try {
            canvas.loadFromJSON(
                JSON.parse(data),
                canvas.renderAll.bind(canvas));
            showSuccess('Sikeres fájlbetöltés!');
        } catch (e) {
            console.error('Problem with JSON loading');
            showError('Sikertelen fájl visszatöltés!')
        }
    });
    reader.readAsText(file);
}

export function jsonImport() {
    document.getElementById('json-input').addEventListener('change', handleFileInputChange);
}