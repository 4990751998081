const REGEX_MAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function isValid(params) {
    let {type, data} = params;

    if (type === 'email') {
        return validateEmail(data);
    }
}

function validateEmail(data) {
    return REGEX_MAIL.test(data.toLowerCase());
}