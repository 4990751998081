import '../css/index.css';
import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/js/all';
import fabric from 'fabric';
import 'fabric-history';
import {
    moveTool,
    textTool,
    drawRect,
    drawLineAngle1,
    drawLineX,
    drawLineAngle2,
    drawLineY,
    freehandTool
} from './tools';
import {canvas, grid} from './shared';
import {enableBtnById, createGrid, drawRulers, createRulerNumbers} from './utils';
import {loadFromLocalStorage, saveToStorage, clearStorage} from "./storage";
import {clearCanvas} from "./canvasFunctions";
import {historyFunction, clearHistory} from "./history";
import {getObjectDimensions, addDimension, resizeClickHandler} from "./dimensions";
import {movingSteps} from "./config";
import config from '../config.json';
import {svgExport, jsonExport, jsonImport, sendAsEmail, showSendModal} from './fileFunctions';
import {isTextEvent} from "./shared";
import {Modal} from 'bootstrap';

export const sendModal = new Modal(document.getElementById('sendModal'));

let drawingBtns = document.getElementsByClassName('drawing-buttons');
let historyBtns = document.getElementsByClassName('history-buttons');

let activeTool = '';

function init() {
    backBtnConfig();
    createGrid();
    drawRulers();
    createRulerNumbers();
    loadFromLocalStorage();
    clearHistory();
}

function backBtnConfig() {
    let btn = document.getElementById('nav-back');
    if (btn) {
        btn.setAttribute('href', config.homeUrl);
    }
}

function onKeyDown(ev) {
    let activeObjects = canvas.getActiveObjects();

    if (ev.key === 'Delete') {
        if (activeObjects.length) {
            activeObjects.forEach((elem) => {
                let type = elem.get('type');

                /**
                 * ha egy i-text típusú elem éppen
                 * editálva van, ne engedje törölni
                 */
                if (type === 'i-text' && isTextEvent) {
                    return;
                }
                canvas.remove(elem);
                canvas.discardActiveObject();
                canvas.renderAll();
            });
        }
        getObjectDimensions();
    }

    let keyCode = ev.keyCode || ev.which;
    let moveKeyCodes = [37, 38, 39, 40];

    if (moveKeyCodes.indexOf(keyCode) > -1 && activeObjects.length > 0) {
        moveObjectsByKeyboard(ev, keyCode, activeObjects);
    }
}

function moveObjectsByKeyboard(ev, keyCode, activeObjects) {
    if (Array.isArray(activeObjects)) {
        // prevent scrolling
        ev.preventDefault();
        activeObjects.forEach(obj => {
            switch (keyCode) {
                case 37: // left
                    obj.left = obj.left - movingSteps;
                    break;
                case 38: // up
                    obj.top = obj.top - movingSteps;
                    break;
                case 39: // right
                    obj.left = obj.left + movingSteps;
                    break;
                case 40: // down
                    obj.top = obj.top + movingSteps;
                    break;
            }
            obj.setCoords();
            canvas.requestRenderAll();
        });

    }
}

function setActiveObjectOpacity(opacity) {
    let obj = canvas.getActiveObject();
    if (obj && opacity) {
        obj.set({
            opacity: opacity
        });
    }
}

/**
 * Canvas events
 */
// Zoom
canvas.on('mouse:wheel', function (opt) {
    let delta = opt.e.deltaY;
    let zoom = canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 1) zoom = 1;
    canvas.setZoom(zoom);
    opt.e.preventDefault();
    opt.e.stopPropagation();

    // rulers újrarajzolás
    drawRulers();
});

// Drag canvas alt + click
canvas.on('mouse:down', function (opt) {
    if (activeTool && activeTool !== 'selection') {
        let {x, y} = opt.absolutePointer;
        switch (activeTool) {
            case 'rect':
                drawRect(x, y);
                enableBtnById('undo');
                setActiveTool('selection');
                break;
            case 'lineX':
                drawLineX(x, y);
                enableBtnById('undo');
                setActiveTool('selection');
                break;
            case 'lineY':
                drawLineY(x, y);
                enableBtnById('undo');
                setActiveTool('selection');
                break;
            case 'lineAngle1':
                drawLineAngle1(x, y);
                enableBtnById('undo');
                setActiveTool('selection');
                break;
            case 'lineAngle2':
                drawLineAngle2(x, y);
                enableBtnById('undo');
                setActiveTool('selection');
                break;
            case 'freehand':
                freehandTool();
                enableBtnById('undo');
                break;
            case 'text':
                textTool(x, y);
                enableBtnById('undo');
                setActiveTool('selection');
                break;
            case 'move':
                moveTool(opt);
                enableBtnById('undo');
                break;
        }
    } else {
        let evt = opt.e;
        if (evt.altKey === true) {
            moveTool(opt);
        }
    }
});

canvas.on('mouse:move', function (opt) {
    if (this.isDragging) {
        let e = opt.e;
        let vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;

        let zoom = canvas.getZoom();

        // pan bounding
        let actualCanvasViewportTransform = this.viewportTransform;
        // left and right
        if (actualCanvasViewportTransform[4] >= 0) {
            // left
            actualCanvasViewportTransform[4] = 0;
        } else if (actualCanvasViewportTransform[4] < 600 - (600 * zoom)) {
            // right
            actualCanvasViewportTransform[4] = 600 - (600 * zoom);
        }

        // up
        if (actualCanvasViewportTransform[5] >= 0) {
            // up
            actualCanvasViewportTransform[5] = 0;

        } else if (actualCanvasViewportTransform[5] < 600 - (600 * zoom)) {
            // down
            actualCanvasViewportTransform[5] = 600 - (600 * zoom);

        }
    }
});

function onSelectionHandler(ev) {
    if (ev.target) {
        if (ev.selected && ev.selected.length > 1) {
            ev.target.setControlsVisibility({mtr: false});
        }
    }

    getObjectDimensions();
}

canvas.on('selection:created', onSelectionHandler);

canvas.on('selection:updated', onSelectionHandler);

canvas.on('selection:cleared', onSelectionHandler);

canvas.on('mouse:up', function () {
    // on mouse up we want to recalculate new interaction
    // for all objects, so we call setViewportTransform
    this.setViewportTransform(this.viewportTransform);
    this.isDragging = false;
    this.selection = true;
});

// snap to grid
canvas.on("object:moving", function (opt) {
    setActiveObjectOpacity(0.5);
    opt.target.set({
        left: Math.round(opt.target.left / grid) * grid,
        top: Math.round(opt.target.top / grid) * grid
    });
});

// snap to grid scale
// https://stackoverflow.com/questions/44147762/fabricjs-snap-to-grid-on-resize
canvas.on('object:scaling', function (opt) {

    // set opacity to 0.5 while scale
    setActiveObjectOpacity(0.5);

    if (opt.target.get('type') === 'line') {
        let angle = opt.target.angle;
        if (angle === 45 || angle === 135) {
            getObjectDimensions();
            return;
        }
    }

    let target = opt.target,
        w = target.width * target.scaleX,
        h = target.height * target.scaleY,
        snap = { // Closest snapping points
            top: Math.round(target.top / grid) * grid,
            left: Math.round(target.left / grid) * grid,
            bottom: Math.round((target.top + h) / grid) * grid,
            right: Math.round((target.left + w) / grid) * grid
        },
        threshold = grid,
        dist = { // Distance from snapping points
            top: Math.abs(snap.top - target.top),
            left: Math.abs(snap.left - target.left),
            bottom: Math.abs(snap.bottom - target.top - h),
            right: Math.abs(snap.right - target.left - w)
        },
        attrs = {
            scaleX: target.scaleX,
            scaleY: target.scaleY,
            top: target.top,
            left: target.left
        };
    switch (target.__corner) {
        case 'tl':
            if (dist.left < dist.top && dist.left < threshold) {
                attrs.scaleX = (w - (snap.left - target.left)) / target.width;
                attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
                attrs.top = target.top + (h - target.height * attrs.scaleY);
                attrs.left = snap.left;
            } else if (dist.top < threshold) {
                attrs.scaleY = (h - (snap.top - target.top)) / target.height;
                attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
                attrs.left = attrs.left + (w - target.width * attrs.scaleX);
                attrs.top = snap.top;
            }
            break;
        case 'mt':
            if (dist.top < threshold) {
                attrs.scaleY = (h - (snap.top - target.top)) / target.height;
                attrs.top = snap.top;
            }
            break;
        case 'tr':
            if (dist.right < dist.top && dist.right < threshold) {
                attrs.scaleX = (snap.right - target.left) / target.width;
                attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
                attrs.top = target.top + (h - target.height * attrs.scaleY);
            } else if (dist.top < threshold) {
                attrs.scaleY = (h - (snap.top - target.top)) / target.height;
                attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
                attrs.top = snap.top;
            }
            break;
        case 'ml':
            if (dist.left < threshold) {
                attrs.scaleX = (w - (snap.left - target.left)) / target.width;
                attrs.left = snap.left;
            }
            break;
        case 'mr':
            if (dist.right < threshold) attrs.scaleX = (snap.right - target.left) / target.width;
            break;
        case 'bl':
            if (dist.left < dist.bottom && dist.left < threshold) {
                attrs.scaleX = (w - (snap.left - target.left)) / target.width;
                attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
                attrs.left = snap.left;
            } else if (dist.bottom < threshold) {
                attrs.scaleY = (snap.bottom - target.top) / target.height;
                attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
                attrs.left = attrs.left + (w - target.width * attrs.scaleX);
            }
            break;
        case 'mb':
            if (dist.bottom < threshold) attrs.scaleY = (snap.bottom - target.top) / target.height;
            break;
        case 'br':
            if (dist.right < dist.bottom && dist.right < threshold) {
                attrs.scaleX = (snap.right - target.left) / target.width;
                attrs.scaleY = (attrs.scaleX / target.scaleX) * target.scaleY;
            } else if (dist.bottom < threshold) {
                attrs.scaleY = (snap.bottom - target.top) / target.height;
                attrs.scaleX = (attrs.scaleY / target.scaleY) * target.scaleX;
            }
            break;
    }
    target.set(attrs);
    getObjectDimensions();
});

// opacity to 1
canvas.on('mouse:up', function () {
    setActiveObjectOpacity(1);
});


function setActiveTool(id) {
    activeTool = id;
    canvas.isDrawingMode = false;
    for (let i = 0; i < drawingBtns.length; i++) {
        drawingBtns[i].classList.remove('btn-primary');
        drawingBtns[i].classList.add('btn-secondary');
    }
    let toolBtn = document.getElementById(id);
    toolBtn.classList.remove('btn-secondary');
    toolBtn.classList.add('btn-primary');
}

function drawingFunction() {
    setActiveTool(this.id);
}

/**
 * Document events
 */
document.getElementById("sendModalButton").addEventListener("click", showSendModal);
document.getElementById("send-as-email").addEventListener("submit", sendAsEmail);
document.getElementById("svg-export").addEventListener("click", svgExport);
document.getElementById("json-export").addEventListener("click", jsonExport);
document.getElementById("json-import").addEventListener("click", jsonImport);
document.getElementById("save").addEventListener("click", saveToStorage);
document.getElementById("load").addEventListener("click", loadFromLocalStorage);
document.getElementById("clear").addEventListener("click", clearCanvas);
document.getElementById("clear-storage").addEventListener("click", clearStorage);
document.getElementById("add-dimensions").addEventListener("click", addDimension);
document.getElementById("resize").addEventListener("click", resizeClickHandler);

for (let i = 0; i < drawingBtns.length; i++) {
    drawingBtns[i].addEventListener('click', drawingFunction, false);
}

for (let i = 0; i < historyBtns.length; i++) {
    historyBtns[i].addEventListener('click', historyFunction, false);
}
// 'Delete' keydown
document.addEventListener('keydown', onKeyDown);

window.addEventListener("load", function () {
    init();
});