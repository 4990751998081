import {canvas, objectDefaults} from "./shared";
import {disableBtnById, enableBtnById} from "./utils";

function resetPropsIfNeeded() {
    canvas.getObjects().forEach(function (el) {
        let type = el.get('type');
        let selectable = el.get('selectable');
        if (type !== 'i-text' && selectable) {
            for (let prop in objectDefaults) {
                el.set(prop, objectDefaults[prop]);
            }
            el.set('strokeUniform', true);
            el.set('noScaleCache', false);
            el.setControlsVisibility({mtr: false});
        }
        canvas.requestRenderAll();
    });
}

export function clearHistory() {
    canvas.clearHistory();
    disableBtnById('redo');
    disableBtnById('undo');
}

export function historyFunction() {
    if (this.id === 'undo') {
        canvas.undo();
        resetPropsIfNeeded();
    } else {
        canvas.redo();
        resetPropsIfNeeded();
    }

    // check history, set btns
    canvas.historyUndo.length === 0 ? disableBtnById('undo') : enableBtnById('undo');
    canvas.historyRedo.length === 0 ? disableBtnById('redo') : enableBtnById('redo');
}
